/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'post_type_archive_case_study': {
			init: function() {
				function load_case_studies(page_num) {
					var tax_query = {}, page = 0;
					if (page_num) {
						page = page_num;
					}
					$('.filter input[type="checkbox"]:checked, .filter .btn.active').each(function () {
						var taxo = $(this).attr('data-taxo');
						tax_query[taxo] = Array.isArray(tax_query[taxo]) ? tax_query[taxo] : [];
						tax_query[taxo].push($(this).val());
					});
					$.ajax({
						'url': admin_ajax.ajax_url,
						'method': 'POST',
						'data': {
							'action': 'get_case_study',
							'tax_query': tax_query,
							'page': page,
							'search': $('#search_field').val(),
						},
						beforeSend: function () {
							$('#search .fas').addClass('fa-spin');
							$('.waiting').addClass('wait');
							console.log($('#search_field').val());
						},
						success: function (data) {
							$('main.main .posts-list').html(data);
							$('.post_btn').magnificPopup({
								type: 'ajax',
								closeOnBgClick: false,
								closeOnContentClick: false,
								overflowY: 'scroll', // as we know that popup content is tall we set scroll overflow by default to avoid jump
								callbacks: {
									parseAjax: function(mfpResponse) {
										console.log(mfpResponse);
										var title = '<div class="d-flex mb-5 align-items-center"><h1 class="mr-3">'+mfpResponse.data.title.rendered+'</h1>'+ mfpResponse.data.tags_html +'</div>';
										var content = mfpResponse.data.content.rendered;
										mfpResponse.data = '<div>' + title  + content +'</div>';
									},
									ajaxContentAdded: function() {
										$('.mfp-content a').attr('target','_blank');
									}
								}
							});
						},
						complete: function() {
							$('.waiting').removeClass('wait');
							$('#search .fas').removeClass('fa-spin');
						}
					});
				}

				load_case_studies();

				$('#reset').on('click',function(e) {
					e.preventDefault();
					$('.filter .btn.active').removeClass('active');
					$('.filter input[type="checkbox"]:checked').attr('checked',false);
					$('#search_field').val('');
					load_case_studies();
				});

				$('.filter input[type="checkbox"], .filter .btn').on('click',function () {
					$(this).toggleClass('active');
					load_case_studies();
					if( $(this).closest('.filter').find('.toggler').hasClass('collapsed') ) {
						$(this).closest('.filter').find('input:not(:checked)').closest('.form-check').slideUp(200);
					}
				});

				$('#search_field').keyup(function(e) {
				    if (e.which === 13) {
						e.preventDefault();
						load_case_studies();
				        // do it
				    }
				});

			},
			finalize: function() {
				var href = window.location.hash.replace('#','');
				console.log(href);
				if( href ) {
					$(href).trigger('click');
					$.magnificPopup.open({
						items: {
						  src: '/wp-json/wp/v2/case_study/'+href
						},
						type: 'ajax',
						closeOnBgClick: false,
						closeOnContentClick: false,
						overflowY: 'scroll', // as we know that popup content is tall we set scroll overflow by default to avoid jump
						callbacks: {
							parseAjax: function(mfpResponse) {
								var title = '<div class="d-flex mb-5 align-items-center"><h1 class="mr-3">'+mfpResponse.data.title.rendered+'</h1>'+ mfpResponse.data.tags_html +'</div>';
								var content = mfpResponse.data.content.rendered;
								mfpResponse.data = '<div>' + title  + content +'</div>';
							},
							ajaxContentAdded: function() {
								console.log(this.content);
								$('.mfp-content a').attr('target','_blank');
							}
						}
					  });
				}
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'sidebar_primary': {
			init: function() {

				$('.toggler').on('click',function() {
					$(this).toggleClass('collapsed').closest('.filter').find('input:not(:checked)').closest('.form-check').slideToggle(200);
				});
			}
		},
		'post_type_archive': {
			init: function() {
				$('input[type="radio"]').on('change',function(){
					$.ajax({
						'url': '/wp-json/wp/v2/'+$('input[type="radio"]:checked').attr('post_type')+'/'+$('input[type="radio"]:checked').val(),
						'method': 'GET',
						beforeSend: function () {
							$('.waiting').addClass('wait');
						},
						success: function (data) {
							$('main.main .posts-list').html('<h1>'+ data.title.rendered + '</h1>' + data.content.rendered);
						},
						complete: function() {
							$('.waiting').removeClass('wait');
						}
					});
				});
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
